import Image from 'next/image';

import { minWidth } from '@hultafors/shared/style';

import { ImageBlockFragment } from '@hultafors/eripiowear/types';

import { Grid } from '../grid/grid';
import { GridChild } from '../grid-child/grid-child';

import { ImageBlockStyled, ImageContainer } from './image-block.styled';

export interface ImageBlockProps extends ImageBlockFragment {
  priority?: boolean;
}

export const ImageBlock: React.FC<ImageBlockProps> = ({
  image,
  priority = false,
}) => {
  const sizes = [
    'calc(100vw - 40px)',
    minWidth.desktop`calc(100vw - 220px)`,
    minWidth.desktopLarge`1200px`,
  ].join(', ');
  return (
    <ImageBlockStyled>
      <Grid>
        <GridChild
          columnSpan={[
            { columns: 5 },
            { breakpoint: 'mobileMax', columns: 12 },
            { breakpoint: 'desktop', columns: 10, start: 2 },
          ]}
        >
          <ImageContainer>
            <Image
              src={image?.responsiveImage?.src || ''}
              alt={image?.alt || ''}
              blurDataURL={image?.responsiveImage?.base64 || ''}
              placeholder={image?.responsiveImage?.base64 ? 'blur' : 'empty'}
              fill
              sizes={sizes}
              priority={priority}
            />
          </ImageContainer>
        </GridChild>
      </Grid>
    </ImageBlockStyled>
  );
};
